import React, { Fragment } from 'react'
import SEO from '@components/SEO'
import Redirect from '@components/encapsulators/Redirect'

function Index () {
  return (
    <Fragment>
      <SEO />

      <Redirect />
    </Fragment>
  )
}

export default Index
